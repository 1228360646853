<template>
	<div class="clinicalTrial" v-if="isShow">
		<van-popup v-model="ispop" position="bottom" class="popBook">
			<div class="book_title">
				知情同意书
			</div>
			<div class="book_content">
				为和您确认报名信息及患者治疗情况，在报名时，我们需要获取您的姓名、联系电话等个人
				信息。我平台郑重承诺您的信息不用于除入组招募以外的其他任何用途。
			</div>
			<div class="book_access" @click="commit">
				我已了解并同意
			</div>
		</van-popup>
		<div class="clinicalTrial_title">
			<div class="clinicalTrial_title_text">{{data.name}}</div>
		</div>
		<div class="suspension-container">
			<div class="suspension">
				<div class="suspension_attribute" >
					<!-- <span>适应症:&emsp;{{data.indication}}</span> -->
					适应症:&emsp;{{data.indication}}
				</div>
				<div class="suspension_attribute">
					<span>药物名称:&emsp;{{data.medicine}}</span>
				</div>
				<div class="suspension_attribute">
					<span>药物类型:&emsp;{{data.medicineType}}</span>
				</div>
				<div class="suspension_attribute">
					<span>试验分期:&emsp;{{data.stage}}</span>
				</div>
				<div class="suspension_status_box">
					<div class="suspension_status">招募中</div>
					<!-- <div class="suspension_date">截止日期{{data.finishDate}}</div> -->
				</div>
			</div>
		</div>
		
		<div class="tab">
			<van-tabs v-model="activeName" type="line" color="#0050A2" line-height="3">
				<van-tab title="项目介绍" name="a">
					<div class="content" v-html="data.projectInfor">
					</div>
				</van-tab>
				<van-tab title="参加标准" name="b">
					<div class="content" v-html="data.projectCriteria">

					</div>
				</van-tab>
				<van-tab title="开展医院" name="c">
					<div class="content" v-html="data.hospital">
						<!-- <table border="0" cellspacing="0">
							<tr class="table_header">
								<td>序号</td>
								<td>医院名称</td>
								<td>主要研究者</td>
								<td>城市</td>
							</tr>
							<tr v-for="(item,index) in hospitalList" :key="index">
								<td>{{index+1}}</td>
								<td>{{item.hospital}}</td>
								<td>{{item.researcher}}</td>
								<td>{{item.city}}</td>
							</tr>
						</table> -->
					</div>

				</van-tab>
			</van-tabs>
		</div>
		<div class="btn_group" v-if="isWeixin">
			<div v-if="data.isLike==1" class="like" @click="favoriteSave(1)">
				<van-icon name="star" color="#828284" size="22" class="icon" /><span>已收藏</span></div>
			<div v-if="data.isLike==0" class="disLike" @click="favoriteSave(0)">
				<van-icon name="star-o" color="#828284" size="22" class="icon" /><span>收藏</span></div>
				<!-- 无需判断是否已报名 2019-12-24 -->
			<!-- <div v-if="data.isApply==1" class="signUp">已报名</div> -->
			<div  class="disSignUp" @click="signUp">我要报名</div>
		</div>
		<div class="btn_three" v-if="!isWeixin">
			<div class="share" @click="shareQr">
				<img src="../../assets/images/icon_share.png"/> <span>分享</span>
			</div>
			<div v-if="data.isLike==1" class="like2" @click="favoriteSave(1)">
				<van-icon name="star" color="#828284" size="22" class="icon" /><span>已收藏</span></div>
			<div v-if="data.isLike==0" class="disLike2" @click="favoriteSave(0)">
				<van-icon name="star-o" color="#828284" size="22" class="icon" /><span>收藏</span></div>
			<!-- 无需判断是否已报名 2019-12-24 -->
			<!-- <div v-if="data.isApply==1" class="signUp">已报名</div> -->
			<div  class="disSignUp2" @click="signUp"><span>我要报名</span></div>
		</div>
		<share-popup ref="sharePopup"
					 :codeUrl="data.qrCode"
					 :config="data.shareConfig"/>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex';
    import Wx from 'weixin-js-sdk';
	import {
		Tab,
		Tabs,
		Icon,
		Popup
	} from 'vant';
    import sharePopup from '@/components/share-popup';
	import clinicalBack from '@assets/icon/clinical_back.png';
	import clinicalTrialApi from '@/api/clinicalTrial/clinicalTrialApi.js';
	import commonApi from '@/api/commonApi.js';
	import {toPath , isEmpty ,hzzmLoginCallBack} from '@/utils/common';

	export default {
		components: {
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Icon.name]: Icon,
			[Popup.name]: Popup,
            sharePopup
		},
		computed: {
			...mapGetters({
				token: 'token',
				isWeixin: 'isWeixin',
				gzhBaseUrl: 'gzhBaseUrl',
			}),
		},
		data() {
			return {
				clinicalBack,
				activeName: 'a',
				id: this.$route.query.id,
				data: null,
				hospitalList: [],
				isShow: false,
				ispop: false,
                ddMemberId: '',
				detialUrl:'',
			}
		},

		methods: {
            shareQr(){
                if ( isEmpty(this.token )  && this.isWeixin ) {
					let miniPath = '/pages/mine/login/login';
					let type = 'clinicalTrialDeail'
					// wx.miniProgram.navigateTo({
					// 	url: `${miniPath}?type=${type}&projectId=${this.id}`
					// })
					toPath(this, { path:'/login', query:{ projectId: this.id, ddMemberId: this.ddMemberId, path: '/clinicalTrialDeail'} }, '/pages/mine/login/login')

				//} else if ( isEmpty(this.token )  && !this.isWeixin ) {//跳转到app登录页面
                //不登录也可以分享
				//	this.detialUrl = this.gzhBaseUrl + "/clinicalTrialDeail?id="+ this.id ,
				//	console.log(" 2：app跳转登录页面 token为空-"+ this.detialUrl)
				//	window.JSBridgeApp('hzzmLogin', this.detialUrl)

				}else {
                    this.$refs.sharePopup.showMyPopup()
                }
            },
			//获取项目详情
			getProject() {
				clinicalTrialApi.project({
					id: this.id
				}).then(rs => {
					// console.log(rs)
					const { projectInfor, projectCriteria, hospital} = rs.data
                    const {shareUrl, shareImg, shareTitle } = rs.data
                    const {timeStamp, appId, nonceStr, paySign, signType} = rs.data;

					this.data = rs.data;
					console.log(this.data.qrCode);
					if(projectInfor){
                        Object.assign(this.data, {
                            projectInfor: projectInfor.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;"'),
                        })
					}
					if(projectCriteria){
                        Object.assign(this.data, {
                            projectCriteria: projectCriteria.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;"'),
                        })
					}
					if(hospital){
                        Object.assign(this.data, {
                            hospital: hospital.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;"'),
                        })
                    }

					// let data=new Date(this.data.finishDate);
					// this.data.finishDate=this.timeFormat(data)
					this.isShow = true;

                    this.data.shareConfig = {
                        text: '药物名称：'+this.data.medicine,
                        title: shareTitle,
                        thumbImage: shareImg,
                        image: '',
                        url: shareUrl
                    }
                    //增加微信环境下的分享
                    this.wxJDKShare(this.data.shareConfig);
				})
			},
			//获取当前项目的医院列表
			getHospital() {
				clinicalTrialApi.hospitalList({
					projectId: this.id
				}).then(rs => {
					this.hospitalList = rs.data
				})
			},
			// 时间格式化 2019-09-08
			timeFormat(time) {
				let year = time.getFullYear();
				let month = time.getMonth() + 1;
				let day = time.getDate();
				return year + '年' + month + '月' + day + '日'
			},
			//报名弹出
			signUp() {
				this.ispop = true;
			},
			// 修改同意确认 --- 点击关闭弹框修改跳转url参数 2019-12-2
			commit() {
				if ( isEmpty(this.token )  && this.isWeixin ) {
					let miniPath = '/pages/mine/login/login';
					let type = 'clinicalTrialDeail'
					// wx.miniProgram.navigateTo({
					// 	url: `${miniPath}?type=${type}&projectId=${this.id}`
					// })
					toPath(this, { path:'/login', query:{ projectId: this.id, ddMemberId: this.ddMemberId, path: '/clinicalTrialDeail'} }, '/pages/mine/login/login')

				} else if ( isEmpty(this.token )  && !this.isWeixin ) {//跳转到app登录页面

					this.detialUrl = this.gzhBaseUrl + "/clinicalTrialDeail?id="+ this.id ,
					console.log(" 2：app跳转登录页面 token为空-"+ this.detialUrl)
					window.JSBridgeApp('hzzmLogin', this.detialUrl)

				} else   {
					// 由试配列表进入详情 url携带患者信息跳转 2019-12-25
					const { inquiryId, inquiryName } = this.$route.query
					this.$router.push({
						path: '/apply',
						query: {
							projectId: this.id,
							projectName: this.data.name,
							type: 1,
							inquiryId: inquiryId || '',
							inquiryName: inquiryName || '',
                            ddMemberId: this.ddMemberId
						}
					})
				}
				this.ispop = false
			},
			//是否收藏状态 0收藏 1取消收藏
			favoriteSave(status) {
				console.log("进入收藏方法：token："+this.token +"---isWEIXIN:"+ this.isWeixin)
				if ( isEmpty(this.token )  && this.isWeixin ) {
					let miniPath = '/pages/mine/login/login';
					let type = 'clinicalTrialDeail'
					// wx.miniProgram.navigateTo({
					// 	url: `${miniPath}?type=${type}&projectId=${this.id}`
					// })
					toPath(this, { path:'/login', query:{ projectId: this.id, ddMemberId: this.ddMemberId, path: '/clinicalTrialDeail'} }, '/pages/mine/login/login')

				} else if ( isEmpty(this.token )  && !this.isWeixin ) {//跳转到app登录页面

					this.detialUrl = this.gzhBaseUrl + "/clinicalTrialDeail?id="+ this.id ,
					console.log(" 2：app跳转登录页面 token为空-"+ this.detialUrl)
					window.JSBridgeApp('hzzmLogin', this.detialUrl)

				} else   {
					console.log("3：我没有登录也进来了"+this.token + "----isWeixin--"+this.isWeixin)

					let data = {
						status: status,
						projectId: this.id,
						projectName: this.data.name
					}
					clinicalTrialApi.favoriteSave(data).then(rs => {
						if (rs.status == 1) {
							if (status == 0) {
								this.data.isLike = 1;
							} else if (status == 1) {
								this.data.isLike = 0;
							}
						}
					})
				}

			},
            //微信环境下的右上角分享配置
            wxJDKShare(shareConfig){
                //在微信网页里的分享
                if(this.isWeixin){
                    let localLink=location.href.split('#')[0];
                    console.log("localLink:---"+encodeURIComponent(localLink));
                    commonApi.getWxConfig(encodeURIComponent(localLink)).then(rs => {

                        Wx.config({
                            debug: false,////生产环境需要关闭debug模式
                            appId: rs.data.appId,//appId通过微信服务号后台查看
                            timestamp: rs.data.timestamp,//生成签名的时间戳
                            nonceStr: rs.data.nonceStr,//生成签名的随机字符串
                            signature: rs.data.signature,//签名
                            jsApiList: ['updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表
                        });
                        Wx.ready(function() {
                            console.log("shareConfig title:--"+shareConfig.title);
                            console.log("shareConfig text:--"+shareConfig.text);
                            console.log("shareConfig url:--"+shareConfig.url);
                            console.log("shareConfig img:--"+shareConfig.thumbImage);
                            Wx.updateAppMessageShareData({
                                title:  shareConfig.title, // 分享标题
                                desc: shareConfig.text, // 分享描述
                                link: shareConfig.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: shareConfig.thumbImage, // 分享图标(不能赋相对路径，一定要是绝对路径)
                                success: () => {
                                }
                            });
                            Wx.updateTimelineShareData({
                                title:  shareConfig.title+"，"+shareConfig.text, // 分享标题
                                link: shareConfig.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: shareConfig.thumbImage, // 分享图标(不能赋相对路径，一定要是绝对路径)
                                success: () => {
                                }
                            });
                        });
                    });

                }
			},

		},

		mounted() {
            const {
                ddMemberId
            }=this.$route.query;
            console.log("ddMemberId have :---"+ddMemberId)
            if(ddMemberId!=null){
                this.ddMemberId=ddMemberId;
            }
			this.getProject();
			this.getHospital();

			 // 将vue组件的要回调的函数暴露出去  将subscanQRCallBack方法绑定到window下面，提供给外部调用
			 window['hzzmLoginCallBack'] = (token) => {
				console.log("1：登录成功后回调传入token：-"+ token)

				hzzmLoginCallBack( token)
			}

		}


	}
</script>

<style scoped="scoped" lang="less">
	.van-tabs__wrap {
		border-bottom: 1px solid #ACABAD;
	}

	.clinicalTrial {
		width: 100%;
		position: relative;

		.popBook {
			width: 100%;
			height: 277px;

			.book_title {
				margin-top: 40px;
				width: 100%;
				font-size: 20px;
				font-weight: 600;
				color: rgba(49, 49, 52, 1);
				text-align: center;
			}

			.book_content {
				margin-top: 30px;
				width: 100%;
				font-size: 16px;
				font-weight: 400;
				color: rgba(172, 171, 173, 1);
				line-height: 23px;
				text-align: left;
				padding: 0 15px;
			}

			.book_access {
				margin-top: 40px;
				width: 100%;
				font-size: 17px;
				font-weight: 500;
				color: rgba(0, 96, 194, 1);
				line-height: 17px;
				text-align: center;
			}

		}

		.btn_group {
			width: 100%;
			height: 49px;
			position: fixed;
			bottom: 0px;

			div {
				display: inline-block;
				height: 49px;
				line-height: 49px;
				text-align: center;
			}

			.like {
				width: 128px;
				background: rgba(255, 255, 255, 1);
				font-size: 17px;
				color: rgba(130, 130, 132, 1);
				border-top: 1px solid #F7F7F7;
				box-sizing: content-box;

				.icon {
					vertical-align: text-top;
				}

				span {
					margin-left: 7px;
				}
			}

			.signUp {
				width: 247px;
				height: 49px;
				background: rgba(130, 130, 132, 1);
				font-size: 17px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
			}

			.disLike {
				width: 128px;
				background: rgba(255, 255, 255, 1);
				font-size: 17px;
				color: rgba(130, 130, 132, 1);
				border-top: 1px solid #F7F7F7;
				box-sizing: content-box;

				.icon {
					vertical-align: text-top;
				}

				span {
					margin-left: 7px;
				}
			}

			.disSignUp {
				width: 247px;
				height: 49px;
				background: #0060C2;
				font-size: 17px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				span {
					margin-left: 7px;
				}
			}
		}

		.btn_three {
			width: 100%;
			height: 49px;
			position: fixed;
			bottom: 0px;

			div {
				display: inline-block;
				height: 49px;
				//line-height: 49px;
				text-align: center;
			}

			.share {
				width:113px;
				height:48px;
				background:rgba(255,255,255,1);
				border:1px solid rgba(238,238,238,1);
				box-sizing: content-box;

				img {
					width:17px;
					height:17px;
					padding-left: 10px;
					padding-top: 10px;
				}

				span {

					font-size:17px;
					font-family:PingFang SC;
					font-weight:400;
					color:rgba(130,130,132,1);
					line-height:32px;
				}
			}

			.like2 {
				width: 113px;
                height:49px;
				background: rgba(255, 255, 255, 1);
				font-size: 17px;
				color: rgba(130, 130, 132, 1);
				border-top: 1px solid #F7F7F7;
				box-sizing: content-box;
                display: inline-block;

                line-height: 49px;
                text-align: center;
				.icon {
					vertical-align: text-top;
				}

				span {
					margin-left: 5px;
				}
			}

			.signUp2 {
				width: 247px;
				height: 49px;
				background: rgba(130, 130, 132, 1);
				font-size: 17px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
			}

			.disLike2 {
				width: 113px;
				height:49px;
				background: rgba(255, 255, 255, 1);
				font-size: 17px;
				color: rgba(130, 130, 132, 1);
				border-top: 1px solid #F7F7F7;
				box-sizing: content-box;
				display: inline-block;

				line-height: 49px;
				text-align: center;

				.icon {
					vertical-align: text-top;
				}

				span {
					margin-left: 7px;
				}
			}

			.disSignUp2 {
				width: 147px;
				height: 49px;
				background: #0060C2;
				font-size: 17px;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				line-height: 49px;
				text-align: center;
				span {
					margin-left: 7px;
				}
			}
		}

		.tab {
			width: 100%;
			// position: relative;
			// top: 190px;
			margin-top: 40px;

			.content {
				width: 100%;
				height: auto;
				padding: 10px;
				margin-bottom: 80px;
				font-size: 18px;
				line-height: 30px;
				text-indent: 2em;
				font-family: PingFang SC;

				img {
					width: 100%;
					height: auto;
				}
				table {
					width: 100%;

					tr {

						td {
							border: none;
							text-align: center;
							font-size: 16px;
							font-weight: 400;
							color: rgba(130, 130, 132, 1);
							line-height: 23px;
							padding: 10px;
							border-bottom: 1px solid #ACABAD;

							&:first-child {
								width: 12%;
							}

							&:nth-child(2) {
								width: 45%;
								// text-align: left;
							}

							&:nth-child(3) {
								width: 25%;
							}
						}
					}

					.table_header {
						background: #0050A2;
						font-size: 15px;
						color: rgba(255, 255, 255, 1);

						td {
							text-align: center;
							color: #FFFFFF;
							padding: 0;
							height: 25px;
							line-height: 25px;
							border: none;
						}
					}
				}

			}
		}
		.suspension-container {
			position: relative;
			// padding-top:125px;
		}
		.suspension {
			margin: -44px auto 0;
			width: 345px;
			height: auto;
			background: rgba(255, 255, 255, 1);
			box-shadow: 0px 4px 8px 0px rgba(162, 162, 162, 0.5);
			border-radius: 5px;
			// position: absolute;
			// top: -44px;
			// left: 50%;
			// margin-left:  -172.5px;
			padding: 10px 15px;

			.suspension_attribute {
				font-size: 16px;
				font-weight: 400;
				color: rgba(90, 90, 93, 1);
				// background: red;
				height: auto;
				padding-bottom: 5px;

				&:first-child {
					span {
						&:last-child {
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 5;
							line-clamp: 5;
							-webkit-box-orient: vertical;

						}
					}
				}

			}

			.suspension_status_box {
				height: 23px;
				margin-top: 5px;

				div {
					display: inline-block;
					vertical-align: top;
				}

				.suspension_status {
					width: 54px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					background: rgba(250, 196, 0, 1);
					border-radius: 2px;
					font-size: 14px;
					font-weight: 600;
					color: rgba(255, 255, 255, 1);
				}

				.suspension_date {
					height: 20px;
					line-height: 20px;
					font-size: 14px;
					font-weight: 400;
					color: rgba(172, 171, 173, 1);
					margin-left: 20px;
				}
			}
		}

		.clinicalTrial_title {
			width: 100%;
			height: 160px;
			background: url(../../assets/icon/clinical_back.png) no-repeat;
			background-size: contain;

			.clinicalTrial_title_text {
				font-size: 19px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: rgba(255, 255, 255, 1);
				line-height: 28px;
				text-align: left;
				vertical-align: text-top;
				position: relative;
				top: 15px;
				// background: red;
				padding: 0 15px;
			}
		}
	}
</style>
