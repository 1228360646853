<template>
    <div class="share-popup">
        <cube-popup type="my-popup" position="bottom" :mask-closable="true" ref="myPopup">
            <div class="share-popup-content">
                <div class="share-item-flex">
                    <div  @click="shareWxClick">
                        <img src="../assets/images/icon_sharewx.png" alt="">
                        <div class="text">微信</div>
                    </div>
                    <div @click="sharePyqClick">
                        <img src="../assets/images/icon_pyq.png" alt="">
                        <div class="text">朋友圈</div>
                    </div>
                    <div @click="showCode" v-if="codeUrl.length > 0">
                        <img src="../assets/images/icon_code.png" alt="">
                        <div class="text">二维码</div>
                    </div>
                </div>
            </div>
        </cube-popup>
        <pop-window ref="popWindow" :codeUrl="codeUrl"></pop-window>
    </div>
</template>
<script>
import popWindow from '@/components/pop-window'
export default {
    components: {
        popWindow
    },
    props:{
        config: {
            type: Object,
            default() {
                return {}
            }
        },
        codeUrl: {
            type: String,
            default() {
                return ''
            }
        },
    },
    data() {
        return {
            old_title: ''
        }
    },
     mounted() {
        this.old_title=this.config.title;
        console.log("old_title:---"+this.old_title);
     },
    methods: {
        shareWxClick() {
            // const {text, title, url, thumbImage, image, type } = this.config
            this.config.pyq = "0";
            this.config.title=this.old_title;
            console.log("wxshare:----"+JSON.stringify(this.config));
            window.JSBridgeApp('wxShare',JSON.stringify(this.config))
            this.$refs.myPopup.hide()
        },
        sharePyqClick() {
            // const {text, title, url, thumbImage, image, type } = this.config
            console.log("before:----"+JSON.stringify(this.config));
            this.config.pyq = "1";
            this.config.title = this.config.title+'，'+this.config.text;
            console.log("after:----"+JSON.stringify(this.config));
            window.JSBridgeApp('wxShare',JSON.stringify(this.config))
            this.$refs.myPopup.hide()
        },
        // 显示
        showMyPopup() {
            this.$refs.myPopup.show()
        },
        // 弹出二维
        showCode() {
            this.$refs.myPopup.hide()
            this.$refs.popWindow.showWin=true; 
           
        }
    }
}
</script>
<style lang="less" scoped>
.share-popup {
   .share-popup-content {
        width: 100%;
        background: #fff;
        padding: 1.466667rem 0;
        .share-item-flex {
            display: flex;
            justify-content: space-around;
            >div {
                img {
                    width: 1.28rem;
                    height: 1.28rem;
                    display: block;
                    font-size: 0;
                    margin-bottom: .16rem;
                }
                .text {
                    text-align: center;
                    font-size: 16px;
                    color: #2A2A2E;
                }
            }
        }
   }
}
</style>

