<template>
	<div>
		
		<div class="popwin" v-if="showWin" @click="closeQr">
			
		</div>
		<div class="popWindow" v-if="showWin">
			<div class="title">
				{{textConfig.title}}
			</div>
			<div class="introduce">
				{{textConfig.subTitle}}
			</div>
			<div class="qrImg">
				<img :src="codeUrl" alt="">
			</div>
		</div>
	</div>
	
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		computed:{
			...mapGetters({ 
			    baseUrl: 'baseUrl',
			})
		},
		props: {
			//0药品详情页的分享码
			//1商品详情页的分享码
			//2保单详情的分享码
			type: {
			    type: String,
			    default() {
			        return '0'
			    }
			},
			codeUrl: {
				type: String,
				default() {
			        return ''
			    }
			},
			textConfig: {
				type: Object,
				default() {
					return {
						title: '我的分享码',
						subTitle: '请使用微信扫描'
					}
				}
			}
		},
		data() {
			return {
				showWin:false,							
			}
		},
		methods:{
			shareQr(){
				this.showWin=true;
			},
			closeQr(){
				this.showWin=false;
			},
			init(){
				if(this.type==0){
					
				}else if(this.type==1){
					
				}else if(this.type==2){
					
				}
			},
		},
		mounted() {
			this.init();
		}
	}
</script>

<style lang="less" scoped="scoped">
	.popwin{
		position: fixed;
		height: 100%;
		width: 100%;
		background: #000000;
		left: 0;
		top: 0;
		opacity: 0.3;
		z-index: 101;		
	}
	.popWindow{
		position: fixed;
		left: 50%;
		top:2.53rem;
		width:8.27rem;
		height:8.51rem;
		background:rgba(255,255,255,1);
		border-radius:0.08rem;
		margin-left: -4.135rem;
		z-index: 111;
		.title{
			width: 100%;
			text-align: center;
			color: #009DF3;
			font-size:0.45rem;
			margin-top: 1.33rem;
		}
		.introduce{
			width: 100%;
			text-align: center;
			color:rgba(42,42,46,1);
			font-size:0.45rem;
			margin-top: 0.4rem;
		}
		.qrImg{
			width:3.6rem;
			height:3.6rem;
			text-align: center;
			margin: 0 auto;
				margin-top: 0.9rem;
			img{
				margin-top: 0.23rem;
				width:3.14rem;
				height:3.14rem;
			}
		}
	}
</style>
